import React, { useEffect, useState } from "react";
import sanityClient from "../client.js";
import Login from "../components/Login";
import { useSelector } from "react-redux";
import { useAuth } from "oidc-react";
import Iframe from "react-iframe";
import { useDispatch } from "react-redux";
import { authUpdateSessionActionCreator } from "../store/actions/auth";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import {
  getContactIDHubspot,
  getDebtRegisterData,
  getDebtRegisterToken,
  updateLeadsOfContact,
} from "../utils/mileniApi";
import { signicatSessionLoginVerfication } from "../utils/index.js";
const mode = "prod";
export default function Hero() {
  const signicat = useAuth(); //Data from BankID
  const storeAuth = useSelector((state) => state.auth); //Authentication Data from Redux's Store (User)
  const [heroData, setHeroData] = useState(null);
  const [open, setOpen] = useState(false);
  const [acquiredContactData, setAcquiredContactData] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClickOpen = () => {
    if (storeAuth?.debtRegisterData?.customer?.customerid) {
      navigate("/loanlist");
    } else {
      setOpen(true);
    }
  };
  /*   useEffect(() => {
       getContactIDHubspot("01010750241");
    }, []); */
  useEffect(() => {
    //Reset UI Loaders
    dispatch(
      authUpdateSessionActionCreator({
        isLoginFormOpened: false,
        isFetchingContactData: false,
        isFetchingDebtData: true,
      })
    );
  }, []);

  useEffect(() => {
    if (
      storeAuth?.isLoginFormOpened &&
      storeAuth?.debtRegisterData?.customer?.customerid
    ) {
      navigate("/loanlist");
    } else if (storeAuth?.isLoginFormOpened) {
      setOpen(true);
    }
  }, [
    storeAuth?.isLoginFormOpened,
    storeAuth?.debtRegisterData?.customer?.customerid,
  ]);

  const handleClose = () => {
    setOpen(false);
    dispatch(
      authUpdateSessionActionCreator({
        isLoginFormOpened: false,
      })
    );
  };

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "hero"]{
        heading,
        ingress,
        list,
        buttontext,
        buttonlink,
        image{
        asset->{
          url,
        }
      }
    }`
      )
      .then((data) => setHeroData(data))
      .catch(console.error);
  }, []);
  /**SIGNICAT, HUBSPOT & DEBT-REGISTER */
  const [iframeCount, setIframeCount] = useState(0);
  const [userHasSharedDebtRegisterData, setUserHasSharedDebtRegisterData] =
    useState(false);
  const [debtRegisterToken, setDebtRegisterToken] = useState(null);

  useEffect(() => {
    setIframeCount(0);
  }, []);

  useEffect(() => {
    if (
      signicat?.userData &&
      storeAuth?.user?.phone &&
      storeAuth?.user?.email &&
      !storeAuth?.hubspotContactId
    ) {
      dispatch(
        authUpdateSessionActionCreator({
          isFetchingContactData: true,
        })
      );
      window.scroll({ top: 0 });
      updateLeadsOfContact({
        ssn: `${signicat?.userData?.profile?.nin}`,
        firstname: `${signicat?.userData?.profile?.given_name}`,
        lastname: `${signicat?.userData?.profile?.family_name}`,
        //fullname: `${signicat?.userData?.profile?.name}`,
        email: storeAuth?.user?.email,
        mobilephone: storeAuth?.user?.phone,
        utm_source: storeAuth?.utm?.utm_source?.value || "",
        utm_medium: storeAuth?.utm?.utm_medium?.value || "",
        utm_campaign: storeAuth?.utm?.utm_campaign?.value || "",
        utm_content: storeAuth?.utm?.utm_content?.value || "",
        utm_term: storeAuth?.utm?.utm_term?.value || "",
        fullfort_lanesoknad: "No",
      }).then(async () => {
        // TODO: Add callback to Adservice to register lead SSR.
        // Remember. user phone number need to be md5 hashed,
        // so we can identify inique users, and not pay for double leads to affiliate
        // Check if utm is Adservice. If it is adservice then trigger, else. do not trigger callback.
        if (storeAuth?.utm?.utm_source?.value === "adservice") {
          console.log("Lead kommer fra adservice");
        }
        await getContactIDHubspot(signicat?.userData?.profile?.nin).then(
          async (res) => {
            window.scroll({ top: 0 });
            setAcquiredContactData(true);
            dispatch(
              authUpdateSessionActionCreator({
                hubspotUser: res?.data?.response,
                hubspotContactId: res?.data?.response?.contactId,
                signicat: {
                  userData: {
                    access_token: signicat?.userData?.access_token,
                    expires_at: signicat?.userData?.expires_at,
                    id_token: signicat?.userData?.id_token,
                    profile: signicat?.userData?.profile,
                    refresh_token: signicat?.userData?.refresh_token,
                    scope: signicat?.userData?.scope,
                    session_state: signicat?.userData?.session_state,
                    state: signicat?.userData?.state,
                    token_type: signicat?.userData?.token_type,
                  },
                },
                isFetchingContactData: false,
              })
            );
            setAcquiredContactData(true);
          }
        );
      });
    }
  }, [signicat?.userData, storeAuth?.user?.phone, storeAuth?.user?.email]);

  useEffect(() => {
    if (signicat?.userData?.id_token) {
      getDebtRegisterToken(signicat?.userData?.id_token).then((res) =>
        setDebtRegisterToken(res?.data?.response?.token)
      );
    }
  }, [signicat?.userData?.id_token]);

  useEffect(() => {
    if (iframeCount > 1) {
      setUserHasSharedDebtRegisterData(true);
    }
  }, [iframeCount]);

  useEffect(() => {
    if (
      userHasSharedDebtRegisterData &&
      acquiredContactData &&
      storeAuth?.hubspotContactId
    ) {
      dispatch(
        authUpdateSessionActionCreator({
          isFetchingDebtData: true,
        })
      );
      (async () => {
        setTimeout(async () => {
          await getDebtRegisterData(storeAuth?.hubspotContactId).then((res) => {
            if (res?.data) {
              dispatch(
                authUpdateSessionActionCreator({
                  debtRegisterData: res?.data,
                  isFetchingDebtData: false,
                })
              );
            } else {
              dispatch(
                authUpdateSessionActionCreator({
                  debtRegisterData: null,
                  isFetchingDebtData: false,
                })
              );
            }
          });
        }, 5000);
        //Callback has been triggered
        //Retrieve DebtRegisterData
      })();
    }
  }, [userHasSharedDebtRegisterData, acquiredContactData]);

  useEffect(() => {
    if (
      storeAuth?.loanListRedirectExpected &&
      signicatSessionLoginVerfication() &&
      userHasSharedDebtRegisterData
    ) {
      navigate("/loanlist");
    }
  }, [storeAuth?.loanListRedirectExpected, userHasSharedDebtRegisterData]);
  /**!--SIGNICAT, HUBSPOT & DEBT-REGISTER */
  return (
    <>
      {storeAuth?.isFetchingContactData && !storeAuth?.hubspotContactId && (
        <>
          <div className="loading-holder">
            <span class="loader"></span>
          </div>
        </>
      )}
      {debtRegisterToken &&
        !userHasSharedDebtRegisterData &&
        acquiredContactData &&
        !storeAuth?.debtRegisterData && (
          <>
            <div className="container">
              <div className="iframe-wrapper">
                <Iframe
                  id="gjeldiframe"
                  width="100%"
                  height="1050"
                  frameBorder="0"
                  url={
                    mode === "test"
                      ? `https://qa.infotorg.no/infotorg/new/BrukersesjonKontroller?iproCommand=token&token=${debtRegisterToken}`
                      : `https://www.infotorg.no/infotorg/new/BrukersesjonKontroller?iproCommand=token&token=${debtRegisterToken}`
                  }
                  onLoad={() => {
                    setIframeCount(iframeCount + 1);
                  }}
                />
              </div>
            </div>
          </>
        )}

      {(!debtRegisterToken ||
        storeAuth?.debtRegisterData?.customer?.customerid) &&
        !acquiredContactData &&
        heroData &&
        heroData.map((data, index) => (
          <div className="hero-section" key={index}>
            <div className="container">
              <div className="inner-hero">
                <div className="left">
                  <h1>{data.heading}</h1>
                  <p className="lead">{data.ingress}</p>
                  <Login
                    handleClose={handleClose}
                    open={open}
                    setOpen={setOpen}
                    handleClickOpen={handleClickOpen}
                    buttonText={data.buttontext}
                  />
                </div>
                <div className="right">
                  <img src={data.image.asset.url} alt="right-icon" />
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );

  // Check for login rendering. Render if logged inn.
  // TODO: Render if user is logged inn
}
