import React, { useEffect, useLayoutEffect, useState } from "react";
import { ReactComponent as Logo } from "../assets/pl-logo.svg";
import { ReactComponent as MenuIcon } from "../assets/menu-icon.svg";
import { Link } from "react-router-dom";
import { useAuth } from "oidc-react";
import { useDispatch, useSelector } from "react-redux";
import { authUpdateSessionActionCreator } from "../store/actions/auth";
import { authEndSessionActionCreator } from "../store/actions/auth";
import { useNavigate } from "react-router-dom";
import PersonPinIcon from '@mui/icons-material/PersonPin';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  signicatSignOutAssistance,
  signicatSessionLookup,
  signicatSessionLoginVerfication,
} from "../utils";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PersonPin from "@mui/icons-material/PersonPin";
import { Logout } from "@mui/icons-material";
export default function Header() {
  const signicat_session_local = signicatSessionLookup();
  const signicat = useAuth(); //Data from BankID
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [displayAuthAction, setDisplayAuthAction] = useState(null);
  const storeAuth = useSelector((state) => state.auth); //Authentication Data from Redux's Store (User)
  const mode = "prod";
  const events = {
    listeners: {},
    timeouts: {},
    intervals: {}
  }
  const signOutUser = async () => {
    signicat.userManager
      .signoutRedirect({
        post_logout_redirect_uri: mode === "test" ? "http://localhost:3000" : "https://pengelegen.no",
        useReplaceToNavigate: true,
        id_token_hint:
          signicat?.userData?.id_token || signicat_session_local?.id_token || storeAuth?.userData?.id_token,
        state: "logged-out",
      })
      .then(() => {
        dispatch(authEndSessionActionCreator({}));
      })
      .catch((error) => {
        console.log("Failure on Sign-Out attempt ::: ", error);
      });
  };
  useEffect(() => {
    const signicatSession = signicatSessionLookup() || {};
    if (signicatSessionLoginVerfication() && ((signicatSession?.userData?.expires_at * 1000) < Date.now())) {
      console.log("Trigger SO ::: ", 1);
      setDisplayAuthAction(true);
      //Set Timer for Logout Redirection
    }
    else if (signicat?.userData?.expires_at && ((signicat?.userData?.expires_at * 1000) < Date.now())) {
      console.log("Trigger SO ::: ", 2);
      dispatch(authEndSessionActionCreator({}));
      setDisplayAuthAction(false);
      signicatSignOutAssistance();
      signOutUser(); // Automatically signout a user if condition is not set
    } else if (storeAuth?.signicat?.userData?.expires_at && ((storeAuth?.signicat?.userData?.expires_at * 1000) < Date.now())) {
      console.log("Trigger SO ::: ", 3);
      dispatch(authEndSessionActionCreator({}));
      setDisplayAuthAction(false);
      signicatSignOutAssistance();
      signOutUser(); // Automatically signout a user if condition is not set
    } 
  }, []);

  useEffect(() => {
    if ((
      ((
        signicat?.userData?.expires_at && signicat?.userData?.expires_at * 1000) > Date.now())
      ||
      (((storeAuth?.signicat?.userData?.expires_at && storeAuth?.signicat?.userData?.expires_at * 1000) > Date.now()))
        &&
        storeAuth?.debtRegisterData?.customer?.customerid
    )
    ) {
      setDisplayAuthAction(true);
    } else {
      setDisplayAuthAction(false);
    }

  }, [signicat?.userData?.expires_at, storeAuth])

  useEffect(() => {
    //Timer
    events.intervals["auto_logout"] = setInterval(() => {
      if ((
        (
          signicat?.userData?.expires_at && signicat?.userData?.expires_at * 1000) < Date.now()
        ||
        ((storeAuth?.signicat?.userData?.expires_at && (storeAuth?.signicat?.userData?.expires_at * 1000 < Date.now()))
        ))) {
        dispatch(authEndSessionActionCreator({}));
        setDisplayAuthAction(false);
        signicatSignOutAssistance();
        signOutUser(); // Automatically signout a user if condition is not set
      }
    }, 15 * 1000);
    return (() => clearInterval(events.intervals["auto_logout"]));
  }, []);

  useEffect(() => {
    if (window?.location?.search) {
      const utm = window.location.search
        .replace("?", "")
        .split("&")
        .map(item => {
          return {
            name: `${item}`.split("=")[0],
            value: `${item}`.split("=")[1],
          }
        });
      const utmObject = {};
      console.log("Unparsed UTM ::: ", window.location.search);
      Object.values(utm).forEach((item) => {
        console.log("Name ::: ", item)
        utmObject[item?.name] = item
      });
      console.log("UTM ::: ", utm);
      console.log("UTM Object ::: ", utmObject);
      // Store cookie for aclid Adservice

      dispatch(authUpdateSessionActionCreator({
        utm: {
          utm_source: utmObject["utm_source"] || storeAuth?.utm?.utm_source || "",
          utm_medium: utmObject["utm_medium"] || storeAuth?.utm?.utm_medium || "",
          utm_campaign: utmObject["utm_campaign"] || storeAuth?.utm?.utm_campaign || "",
          utm_content: utmObject["utm_content"] || storeAuth?.utm?.utm_content || "",
          utm_term: utmObject["utm_term"] || storeAuth?.utm?.utm_term || "",
          asclid: utmObject["asclid"] || storeAuth?.utm?.asclid || "",
        }
      }))
    }
  }, []);

  const [ctaVissible, setCtaVissible] = useState(false);

  const openLoginForm = () => {
    dispatch(
      authUpdateSessionActionCreator({
        isLoginFormOpened: true
      })
    );
    if (window.location.pathname != "/") {
      navigate("/");
    }
  };

  useEffect(() => {
    window.onscroll = (e) => {
      if (window.pageYOffset > 300) {
        setCtaVissible(true)
      } else {
        setCtaVissible(false)
      }
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 450) {
        setCtaVissible(false)
      }
    }

  });

  return (
    <>
      <header>
        <div className="container">
          <div className="header-holder">
            <div className="logo-holder">
              <Link to="/">
                <Logo />
              </Link>
            </div>
            <div className="menu">
              <div className="side-wrapper">
                {/*<MenuIcon />*/}

                {displayAuthAction === true && (
                  <button
                    className="logout"
                    onClick={() => {
                      //Add information to firebase and redirect user to sign in to SigniCat
                      signOutUser();
                    }}
                  >
                    Logg ut
                    <LogoutIcon />
                  </button>
                )}

                {displayAuthAction === false && (
                  <button
                    className="loginbtn"
                    onClick={() => openLoginForm()}
                  >
                    Logg in
                    <PersonPinIcon />
                    <span className="red-circ"></span>
                  </button>
                )}

                {ctaVissible && (
                  <div className="faen">
                    <p>Sjekk din gjeld og hva du kan spare</p>
                    <button className="sticky-btn ml-5" onClick={() => openLoginForm()}>
                      Sjekk nå
                      <ArrowForwardIcon />
                    </button>
                  </div>
                )}

              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
