/**
 * Description: Combines all reducers in the reducer's folder and exports to be used in the index.js folder as one.
 */
import { combineReducers } from "redux";
import auth from "./reducers/auth";
import deals from "./reducers/deals";
import support from "./reducers/support";
export default combineReducers({
    //TODO: Add architecture of data here...
    auth,
    deals,
    support,
});
