// src/App.js
import React, { useEffect } from "react";
import "../src/styles/main.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Posts from "./components/Posts";
import AllPosts from "./components/AllPosts";
import OnePost from "./components/SinglePost";
import Index from "./components/Index";
import Creditcards from "./components/Creditcards";
import Personvern from "./components/Personvern";
import {Helmet} from "react-helmet";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import SingleLoan from "./components/SingleLoan";
import Thanks from "./components/Thanks";
import Discounts from "./components/discounts";

import * as firebase from "./firebase";
const theme = createTheme({
  palette: {
    primary: {
      main: "#399050",
    },
  },
});

function App() {

  useEffect(() => {
    window.scroll({ top: 0 });
  }, []);

  return (
    <>
    <Helmet>
        <title>Pengelegen</title>
    </Helmet>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>  
            <Route element={<Index />} path="/" exact />
            <Route element={<Posts />} path="/posts" />
            <Route element={<SingleLoan />} path="/loanlist" />
            <Route element={<OnePost />} path="/:slug" />
            <Route element={<AllPosts />} path="/wordcloud" />
            <Route element={<Thanks />} path="/takk" />
            <Route element={<Personvern />} path="/personvern" />
            <Route element={<Creditcards />} path="/creditcards" />
            <Route element={<Discounts />} path="/discounts" />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}
export default App;
