/**
 * Description: Action's Types and Creators for Authentication purposes using Redux Store
 */
export const AUTH_ACTIONS_TYPES = {
    AUTH_LOGIN: "AUTH_LOGIN",
    AUTH_END_SESSION: "AUTH_END_SESSION",
    AUTH_UPDATE_SESSION: "AUTH_UPDATE_SESSION",
    //TODO: Add more types regarding the desired or intended logic
};

//TODO: Add here action creators if needed in order to permute complex data

export const authLoginActionCreator = (payload) => {
    return {
        type: AUTH_ACTIONS_TYPES.AUTH_LOGIN,
        payload,
    };
};
export const authUpdateSessionActionCreator = (payload) => {
    return {
        type: AUTH_ACTIONS_TYPES.AUTH_UPDATE_SESSION,
        payload,
    };
};
export const authEndSessionActionCreator = (payload) => {
    return {
        type: AUTH_ACTIONS_TYPES.AUTH_END_SESSION,
        payload,
    };
};
