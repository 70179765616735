import React from "react";
import { useNavigate } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
import Thankyou from '../assets/thankyou.jpg'
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const Thanks = (props) => {
    const navigate = useNavigate();
    const goToMainPage = () => {
        navigate("/");
    };

    return (
        <>
            <Header />
            <div className="hero-section" style={{ paddingBottom: '3vw' }}>
                <div className="container">
                    <div className="inner-hero">
                        <div className="left">
                            <h1>Vi har mottatt din søknad!</h1>
                            <p className="lead"></p>
                            <Button variant="outlined" onClick={() => goToMainPage()}>
                                Til forsiden
                                <ArrowForwardIcon sx={{ ml: 1 }} />
                            </Button>
                        </div>
                        <div className="right">
                            <img src={Thankyou} alt="right-icon" />
                        </div>
                    </div>
                </div>
            </div>
            <Footer location={"thank-you-page"} />
        </>
    );
};

export default Thanks;
