import React, { useEffect, useState } from "react";
import sanityClient from "../client.js";
import { Link } from "react-router-dom";
import { iconButtonClasses } from "@mui/material";

export default function Services() {
  const [serviceData, setServiceData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "services"] | order(_updatedAt desc) {
        name,
        undertext,
        servicelink,
        icon{
        asset->{
          url
        }
      }
    }`
      )
      .then((data) => setServiceData(data))
      .catch(console.error);
  }, []);
  return (
    <>
        <div className="services-section">
            <div className="container">
                <div className="services-holder">
                    {serviceData &&
                    serviceData.map((data, index) => (
                        <div className="single-service" key={index}>
                            <div className="icon">
                                <img src={data?.icon?.asset?.url} />
                            </div>
                            <h3>{data?.name}</h3>
                            <p>{data?.undertext}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </>
  );
}