// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDWnCU8SxHjAJ-S0SE2_CuLuM7ssAgpYL4",
    authDomain: "pengelegen.firebaseapp.com",
    projectId: "pengelegen",
    storageBucket: "pengelegen.appspot.com",
    messagingSenderId: "708115450705",
    appId: "1:708115450705:web:f2d831db0ea79e9f912170",
    measurementId: "G-1TPJGK9M1J"
};
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
// Authentication observer
