import React, { useEffect, useState } from "react";
import sanityClient from "../client.js";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Button from '@mui/material/Button';
import { authUpdateSessionActionCreator } from "../store/actions/auth";
import { useDispatch } from "react-redux";

export default function Infobox() {
    const dispatch = useDispatch();
    const [infoData, setInfoData] = useState();
    
    useEffect(() => {
        return () => {
            dispatch(
                authUpdateSessionActionCreator({
                    isLoginFormOpened: false
                })
            );
        }
    }, []);

    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "infobox"][1]{
                heading,
                ingress,
                buttontext,
                buttonlink, 
                list[]{
                    text, 
                    title
                },
                image{  
                    asset->{
                      url, 
                    }
                }
            }`
            )
            .then((data) => setInfoData(data))
            .catch(console.error);
    }, []);

    const openLoginForm = () => {
        dispatch(
            authUpdateSessionActionCreator({
                isLoginFormOpened: true
            })
        );
    };

    return (
        <>
            <div className="infobox">
                <div className="container">

                    <div className="infobox-extension">
                        <div className="inner">
                            <div className="left">
                                <h2>{infoData?.heading}</h2>
                                <p>{infoData?.ingress}</p>
                                {infoData?.buttontext && (
                                    <>
                                    <Button onClick={() => openLoginForm()} variant="outlined">
                                        {infoData?.buttontext}
                                        <ArrowForwardIcon sx={{ ml: 1 }} />
                                    </Button>
                                    </>
                                )}
                                <div className="line"></div>
                                <div className="infoboxes">
                                    {infoData &&
                                        infoData.list.map(x => (
                                            <div className="single-box">
                                                <p><span>{x?.title}</span></p>
                                                <p>{x?.text}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="right">
                                <img src={infoData?.image?.asset?.url} />
                            </div>
                        </div>
                    </div>

                </div> {/* Container */}
            </div> {/* Infobox */}
        </>
    );
}