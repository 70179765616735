import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store"; //root-reducer >> alias to store
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { AuthProvider } from "oidc-react";
import { applyMiddleware, createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { PersistGate } from "redux-persist/integration/react";
import { configureStore } from "@reduxjs/toolkit";
const root = ReactDOM.createRoot(document.getElementById("root"));
const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, store);
const mainStore = configureStore({ reducer: persistedReducer });
const persistor = persistStore(mainStore);
const mode = "prod";
const oidcConfig = {
  onSignIn: async (user) => {
    window.location.hash = "";
  },
  autoSignIn: false,
  automaticSilentRenew: false,
  revokeAccessTokenOnSignout: true,
  authority: mode === "test" ? "https://login-test.signicat.io" : "https://login.signicat.io",
  clientId: mode === "test" ? "t9a4bc94a070144e69f0edada10e0cfde" : "p1e733b0485424e2ea5439c85c1af9ee8",
  clientSecret: mode === "test" ? "MspDozwxww2CFtLd7TBk2jcQUMa7Rsag" : "yMzeR9RTWd5qarNfbvzwGx489PCsICz3",
  responseType: "code",
  scope: "openid profile nin",
  redirectUri: mode === "test" ? "http://localhost:3000" : "https://pengelegen.no",
  post_logout_redirect_uri: mode === "test" ? "http://localhost:3000" : "https://pengelegen.no"
};
root.render(
  <React.StrictMode>
    <Provider store={mainStore}>
      <PersistGate loading={null} persistor={persistor}>
        <AuthProvider {...oidcConfig}>
          <App />
        </AuthProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
