
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../client.js";
import Header from './Header';
import Footer from './Footer';
import { ReactComponent as SideImage } from "../assets/search.svg";
import {Helmet} from "react-helmet";

export default function AllPosts() {
  const [allPostsData, setAllPosts] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0)
    sanityClient
      .fetch(
        `*[_type == "post"]{
            title,
            slug,
        }`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);
  }, []);

  const posts = [];

  allPostsData.map((el, i) => {
    // Check if value already exist
    if(posts.indexOf(el.title[0]) === -1) {
        posts.push(el.title[0])
    }
  })

  // Sort alphabetical order
  posts.sort((a, b) => {
    return a.localeCompare(b);
  });

  return (
    <>
        <Helmet>
            <title>Søk etter Artikkel | Pengelegen</title>
            <meta name="desciption" content="Lån er faktisk en vare, på lik linje med de varene du kan kjøpe i 
                matbutikken. Ønsker du å ta opp et lån, må du betale for 
                det. Når du som kunde skal ta opp lån er det." />
            <link rel="canonical" href={window.location.href} />
        </Helmet>
        <Header />
            <div className="wordcloud">
                <div className="top-section">
                    <div className="container">
                        <div className="inner">
                            <div className="left">
                                <h1>Søk etter Artikkel</h1>
                                <p>Lån er faktisk en vare, på lik linje med de varene du kan kjøpe i 
                                    matbutikken. Ønsker du å ta opp et lån, må du betale for 
                                    det. Når du som kunde skal ta opp lån er det.</p>
                            </div>
                            <div className="right">
                                <SideImage />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="wordcloud-holder">
                        <ul>
                            {posts && 
                                posts.map((post, idx) => (
                                    <>
                                        <li key={idx}>
                                            <h2 key={idx}>
                                                {post}
                                            </h2>
                                            <div key={idx}>
                                                {allPostsData && allPostsData.map((mikeOxLong, mikeLitoris) => (
                                                    <>
                                                        {mikeOxLong.title[0] == post && (
                                                            <p key={mikeLitoris}>
                                                                <Link to={"/" + mikeOxLong.slug.current} key={mikeOxLong.slug.current}>
                                                                    {mikeOxLong.title}
                                                                </Link>
                                                            </p>
                                                        )}
                                                    </>
                                                ))}
                                            </div>
                                        </li>
                                    </>
                                )
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        <Footer location={"all-posts"} />
    </>
  );
}