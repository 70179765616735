/**
 * Description: Reducer's State and Handler for Support purposes using Redux Store
 */
import { SUPPORT_ACTIONS_TYPES } from "../actions/support";

const initialState = {
    gdprVisibility: false,
    /*Any other data can go here as well. Note: The user object is the object from SigniCat Login */
};
const reducer = (
    state = initialState,
    action = { type: "", payload: null }
) => {
    switch (action.type) {
        case SUPPORT_ACTIONS_TYPES.SET_GDPRVISIBILITY: {
            return {
                ...state,
                gdprVisibility: action.payload,
            };
        }
        default: {
            return state;
        }
    }
};
export default reducer;
