import React, { useEffect, useState } from "react";
import Header from "./Header";
import Posts from './Posts';
import Footer from './Footer';
import Services from './Services';
import Hero from './Hero';
import Infobox from './Infobox';
import Infobox2 from './Infobox2';

export default function Index() {

  useEffect(() => {
    window.scroll({ top: 0});
  }, []);

  return (
    <>
      <Header />
      <Hero />
      <Services />
      <Infobox2 />
      <Posts />
      <Infobox />
      <Footer category="News" />
    </>
  );
}