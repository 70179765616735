import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Personopplysninger from "./formcomponents/personopplysninger";
import Boforhold from "./formcomponents/boforhold";
import Refinance from "./Refinance";
import Arbeidssituasjon from "./formcomponents/arbeidssituasjon";
import Sendsoknad from "./formcomponents/sendsoknad";
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useNavigate } from "react-router-dom";
import DialogContentText from '@mui/material/DialogContentText';
import { Link } from "react-router-dom";
//import { getFunctions, httpsCallable } from "firebase/functions";
import { useSelector } from "react-redux";
function SimpleDialog(props) {
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
  });
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [data, setData] = useState("");
  const watchAllData = watch();
  const { onClose, selectedValue, open } = props;
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("md");
  const [activeStep, setActiveStep] = useState(0);
  const [coApplicant, setCoApplicant] = useState();
  const navigate = useNavigate();

  // API Firebase
  //const functions = getFunctions();
  //const submitToHubspot = httpsCallable(functions, 'submitToHubspot');

  /*   const onSubmit = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      if (activeStep === 3) {
  
        const getCookie = (cName) => {
          const name = cName + "=";
          const cDecoded = decodeURIComponent(document.cookie);
          const cArr = cDecoded.split('; ');
          let res;
          cArr.forEach(val => {
            if (val.indexOf(name) === 0) res = val.substring(name.length);
          })
          return res
        }
  
        submitToHubspot({ formData: watchAllData, hubId: getCookie("hubspotutk") })
          .then((result) => {
         
          });
      }
    }; */

  const onSubmit = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep === 3) {
      submitFormToHubspotLegacy(watchAllData);
    }
  };
  /*   const defaultFormKeys = ['firstname', 'lastname', 'email', 'mobilephone', 'ssn', 'norsk_statsborger__po', 'utdannelse', 'medsoker', 'navn_medsoker', 'e_post_medsoker', 'telefonnummer_medsoker', 'norsk_statsborger__po_medsoker', 'utdannelse_medsoker', 'sivilstatus', 'boforhold', 'antall_barn_under_18_ar', 'boligverdi', 'boliglan__hovedsoker_', 'terminbelop_boliglan', 'leiekostnader', 'ektefelle_samboers_brutto_arsinntekt', 'employment_situation', 'navn_pa_bedriften', 'antall_ar_i_bedriften', 'brutto_arslonn', 'netto_manedslonn', 'annen_inntekt_per_ar', 'har_lan_uten_sikkerhet', 'har_studielan', 'har_kjoretoy_lan', 'har_betalingsanmerkninger', 'total_belop_usikret_gjeld', 'antall_lan_uten_sikkerhet', 'terminbelop_usikret_gjeld', 'studielan', 'terminbelop_studielan', 'bil_mc_bat', 'terminbelop_bil_mc_bat', 'antall_inkasso', 'total_belop_inkasso_og_betalingsanmerkning']; */

  const validationFormKeys = [
    "firstname",
    "lastname",
    "email",
    "mobilephone",
    "ssn",
    "norsk_statsborger__po",
    "utdannelse",
    "sivilstatus",
    "boforhold",
    "antall_barn_under_18_ar",
    "boligverdi",
    "boliglan__hovedsoker_",
    "terminbelop_boliglan",
    "leiekostnader",
    "ektefelle_samboers_brutto_arsinntekt",
    "employment_situation",
    "navn_pa_bedriften",
    "antall_ar_i_bedriften",
    "brutto_arslonn",
    "netto_manedslonn",
    "annen_inntekt_per_ar",
    "har_lan_uten_sikkerhet",
    "har_studielan",
    "har_kjoretoy_lan",
    "har_betalingsanmerkninger",
    "total_belop_usikret_gjeld",
    "antall_lan_uten_sikkerhet",
    "terminbelop_usikret_gjeld",
    "studielan",
    "terminbelop_studielan",
    "bil_mc_bat",
    "terminbelop_bil_mc_bat",
    "antall_inkasso",
    "total_belop_inkasso_og_betalingsanmerkning",
  ];

  const submitFormToHubspotLegacy = async (form) => {
    setIsSubmittingForm(true);
    const _form = {};
    Object.keys(form).forEach((key) => {
      if (form[key]) {
        _form[key] = form[key];
      } else if (!form[key]) {
        _form[key] = "0";
      }
    });

    if (["Pensjonist", "Ufør"].includes(_form?.employment_situation)) {
      _form["annen_inntekt_per_ar"] =
        Number(
          !isNaN(parseInt(_form["additionalMonthlyInsurance"]))
            ? parseInt(_form["additionalMonthlyInsurance"])
            : 0
        ) +
        Number(
          !isNaN(parseInt(_form["annen_inntekt_per_ar"]))
            ? parseInt(_form["annen_inntekt_per_ar"])
            : 0
        );
      delete _form["additionalMonthlyInsurance"];
    } else {
      delete _form["additionalMonthlyInsurance"];
    }
    //Sets 0 to all keys that are null or not available in the user form;
    validationFormKeys.forEach((key) => {
      if (!_form[key]) {
        _form[key] = "0";
      }
    });
    _form["fullfort_lanesoknad"] = true;
    await fetch(`https://backend.mileni.no/api/hubspot/leads-without-contact`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        formguid: "387dd218-9005-4d8a-897c-011cf05332b5",
      },
      body: JSON.stringify(_form),
    })
      .then((res) => res.json())
      .then((res) => {
        //Redirect User to Thank You Page
        if (res?.data?.response?.statusCode === 200) {
          //alert("Forespørselen din er sendt med suksess.");
          navigate("/takk");
        } else {
          alert("Failed to submit form, try again later!");
        }
      })
      .catch((error) => {
        console.error(
          "Failed to execute endpoint with success <SubmitFormToHubspot> >>> ",
          error
        );
        //Display error to user saying that it has failed to submit.
      })
      .finally(() => {
        setIsSubmittingForm(false);
      });
  };

  const handleNext = () => {
    handleSubmit(onSubmit)();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleClose = () => {
    onClose(selectedValue);
  };

  const [alignment, setAlignment] = useState("web");

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  return (
      <>
    <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth={'xl'}
    >
        <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
            <Refinance />
        </DialogContentText>
        </DialogContent>
        <DialogActions>
        </DialogActions>
    </Dialog>
    {/*
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      keepMounted={true}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Send søknad
        <Button
          className="close-btn"
          onClick={() => onClose()}
          variant="outlined"
          size="large"
        >
          Lukk
        </Button>
      </DialogTitle>
      <Box sx={{ maxWidth: 720, padding: "3vw" }}>
        <form
          novalidate="novalidate"
          onSubmit={handleSubmit((data) => setData(JSON.stringify(data)))}
        >
          <Stepper activeStep={activeStep} orientation="vertical">
            <Step>
              <StepLabel>Personopplysninger</StepLabel>
              <StepContent TransitionProps={{ unmountOnExit: false }}>
                {activeStep === 0 && (
                  <>
                    <Personopplysninger
                      register={register}
                      errors={errors}
                      getValues={getValues}
                      coApplicant={coApplicant}
                      setCoApplicant={setCoApplicant}
                    />
                    <Box sx={{ m: 3 }}></Box>
                    <Button
                      variant="contained"
                      size="large"
                      sx={{ mt: 1, mr: 1, outline: "none" }}
                      type="submit"
                      onClick={handleNext}
                    >
                      NESTE
                      <ArrowForwardIcon sx={{ ml: 1 }} />
                    </Button>
                  </>
                )}
              </StepContent>
            </Step>

            <Step>
              <StepLabel>Boforhold</StepLabel>
              <StepContent TransitionProps={{ unmountOnExit: false }}>
                {activeStep === 1 && (
                  <>
                    <Boforhold
                      register={register}
                      errors={errors}
                      getValues={getValues}
                      coApplicant={coApplicant}
                      setCoApplicant={setCoApplicant}
                    />
                    <Button
                      variant="contained"
                      size="large"
                      sx={{ mt: 5, mr: 1, outline: "none" }}
                      type="submit"
                      onClick={handleNext}
                    >
                      NESTE
                      <ArrowForwardIcon sx={{ ml: 1 }} />
                    </Button>
                  </>
                )}
              </StepContent>
            </Step>
            <Step>
              <StepLabel>Arbeidssituasjon</StepLabel>
              <StepContent TransitionProps={{ unmountOnExit: false }}>
                {activeStep === 2 && (
                  <>
                    <Arbeidssituasjon
                      register={register}
                      errors={errors}
                      getValues={getValues}
                      coApplicant={coApplicant}
                      setCoApplicant={setCoApplicant}
                    />
                    <Button
                      variant="contained"
                      size="large"
                      sx={{ mt: 1, mr: 1, outline: "none" }}
                      type="submit"
                      onClick={handleNext}
                    >
                      NESTE
                      <ArrowForwardIcon sx={{ ml: 1 }} />
                    </Button>
                  </>
                )}
              </StepContent>
            </Step>
            <Step>
              <StepLabel>Send søknad</StepLabel>
              <StepContent TransitionProps={{ unmountOnExit: false }}>
                {activeStep === 3 && (
                  <>
                    <Sendsoknad
                      register={register}
                      errors={errors}
                      getValues={getValues}
                      coApplicant={coApplicant}
                      setCoApplicant={setCoApplicant}
                    />
                    <Button
                      variant="contained"
                      size="large"
                      sx={{ mt: 1, mr: 1, outline: "none" }}
                      type="submit"
                      onClick={handleNext}
                    >
                      SEND SØKNAD
                      <ArrowForwardIcon sx={{ ml: 1 }} />
                    </Button>
                    <Box sx={{ m: 5 }}></Box>
                  </>
                )}
              </StepContent>
            </Step>
          </Stepper>
        </form>
      </Box>
                </Dialog>*/}
                </>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function SimpleDialogDemo() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
      <>
      {/* 
    <div>
        <a target="_blank" href="https://go.axofinans.no/aff_c?offer_id=279&aff_id=1467">
            <Button variant="filled">
                Refinansier gjelden nå
                <ArrowForwardIcon />
            </Button>
        </a>
       </div>
        */}
      <div>
           
        <Button variant="filled" onClick={handleClickOpen}>
          Refinansier gjelden nå
          <ArrowForwardIcon />
        </Button>
        <SimpleDialog open={open} onClose={handleClose} />
      </div>
      
   </>
  );
}
export default React.memo(SimpleDialogDemo);
