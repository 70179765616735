// src/component/OnePost.js

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import sanityClient from "../client.js";
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from "@sanity/image-url";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { dividerClasses } from "@mui/material";
import {Helmet} from "react-helmet";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

export default function OnePost() {
  const [postData, setPostData] = useState(null);
  const [catData, setCatData] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == "${slug}"]{
           title,
           slug,
           publishedAt,
           ingress,
           "cats": categories[]->{
                title,
           },
           mainImage{
           asset->{
              _id,
              url
            }
          },
          body,
          "name": author->name,
          "authorImage": author->image
       }`
      )
      .then((data) => setPostData(data[0]))
      .catch(console.error);
  }, [slug]);
  
  if (!postData) return <Box sx={{ width: '100%' }}><Header /><>
  <div className="loading-holder">
      <span class="loader"></span>
  </div>
</></Box>;

  return (
    <>
        <Helmet>
            <title>{postData.title} | Pengelegen</title>
            <meta name="desciption" content={postData.ingress} />
            <link rel="canonical" href={window.location.href} />
        </Helmet>
        <Header />
        <div className="singlepost">
            <div className="top-post">
                
                <div className="container">
                    <div className="post-cover">
                        <img src={urlFor(postData.mainImage).url()} />
                    </div>
                    <h1>{postData.title}</h1>
                    <p className="lead">{postData.ingress}</p>
                    <div className="under-post-cover">
                        <div className="author-info">
                            <Avatar alt="Cindy Baker" src={urlFor(postData.authorImage).url()} />
                            <div className="author-info-desc">
                                <p>{postData.name}</p>
                                <p><b>{postData.publishedAt}</b></p>
                            </div>
                        </div>
                        <div className="categories">
                            <Stack direction="row" spacing={1}>
                            {postData.cats.map((data, index) => (
                                <><Chip variant="outlined" color="primary" key={index} label={data.title} /></>
                            ))} 
                            </Stack>
                        </div>
                    </div>
                </div>

            </div> {/* TOP-POST */}

            <div className="post-container start">
                <BlockContent
                    blocks={postData.body}
                    projectId={sanityClient.clientConfig.projectId}
                    dataset={sanityClient.clientConfig.dataset}
                />
            </div>

        </div>
        <Footer location={"single-post"} />
    </>
  );
}