// src/components/AllPosts.js
//import { isValid, parseISO, format } from 'date-fns'
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../client.js";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Button from '@mui/material/Button';

export default function Posts() {
  const [allPostsData, setAllPosts] = useState([]);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post" && !("Hva er" in categories[]->title)]{
        title,
        slug,
        publishedAt,
        "AuthorName": author->name,
        "AuthorImage": author->image.asset->url, 
        categories[]->{  
            title, 
            hexcolor,
        }, 
        mainImage{
        asset->{
          _id,
          url
        }
      }
    }`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);
  }, []); 
  console.log(allPostsData)
  const myposts = allPostsData.slice(-3);

  return (
    <>
    <div className="post-section">
        <div className="container">
            <h2>Artikler</h2>
            <div className="post-holder">
                {myposts &&
                myposts.map((post, index) => (
                    <Link to={"/" + post.slug.current} key={post.slug.current}>
                        <div className="single-post" key={index}>
                            <img
                                src={post.mainImage.asset.url}
                                alt="image"
                            />
                            <div className="post-info">
                                <h3>{post.title}</h3>
                                {post.categories.map((x) => (
                                    <>
                                        <span 
                                            className="cat-ribbon inline-block mb-1"
                                            style={{backgroundColor: `${x.hexcolor}` }}>
                                            {x.title}
                                        </span>
                                    </>
                                ))}
                                <p>
                                    <div>
                                        <img src={post.AuthorImage} />
                                        {post.AuthorName}
                                    </div>
                                    <time dateTime={post.publishedAt}>{post.publishedAt}</time>
                                </p>
                            </div>
                        </div>
                    </Link> 
                ))}
            </div>
            <div className="flex justify-center mt-12">
                <Link to={"/wordcloud"}>
                    <Button>
                        Se alle artikler
                        <ArrowForwardIcon sx={{ ml: 1 }} />
                    </Button>
                </Link>
            </div>
        </div>
    </div>
    </>
  );
}