/**
 * Description: Reducer's State and Handler for Deals purposes using Redux Store
 */
import { DEALS_ACTIONS_TYPES } from "../actions/deals";

const initialState = {
    deals: [],
    lastUpdated: null,
};
const reducer = (
    state = initialState,
    action = { type: "", payload: null }
) => {
    switch (action.type) {
        case DEALS_ACTIONS_TYPES.SET_DEALS: {
            return {
                ...state,
                deals: (action.payload && action.payload.deals) || "",
                lastUpdated: Date.now(),
            };
        }
        case DEALS_ACTIONS_TYPES.CLEAR_DEALS: {
            return {
                ...state,
                deals: [],
            };
        }
        default: {
            return state;
        }
    }
};
export default reducer;
