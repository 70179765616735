
import React, { useEffect, useState } from "react";
import sanityClient from "../client.js";
import Visalogo from "../assets/visalogo.png";
import Mastercardlogo from "../assets/mastercardlogo.png";
import {Helmet} from "react-helmet";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import RecommendIcon from '@mui/icons-material/Recommend';

export default function Creditcard() {

    const [creditData, setCreditData] = useState([]);

    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "creditcards"] | order(order asc) {
                    name,
                    cardtype, 
                    maxcredit,
                    interestfreedays,
                    age,
                    effectiverent,
                    renteeksempel,
                    buttonlink,
                    boost,
                    buttontext,
                    altbuttontext,
                    benefits[]{
                        hasbenefit, 
                        benefit,
                    },
                    image{
                        asset->{
                            url,
                        }
                    }
            }`
            )
            .then((data) => setCreditData(data))
            .catch(console.error);
    }, []);


    return (
        <>

                <div className="creditcards">
                    {creditData &&
                    creditData.map((detail, index) => (
                        <>
                            <div className={detail?.boost ? 'single-creditcard boost' : 'single-creditcard'}>
                                {detail.boost && (
                                    <>
                                        <div className="chip-holder">
                                            <Chip icon={<RecommendIcon />} label="Anbefalt!" color="success" />
                                        </div>
                                    </>
                                )}
                                <div className="main-info">
                                    <img src={detail?.image.asset.url} />
                                    <div className="details">
                                        {!detail?.cardtype ? (
                                            <img src={Visalogo} />
                                        ) : (
                                            <img src={Mastercardlogo} />
                                        )}
                                        <h3>{detail?.name}</h3>
                                        <p>
                                            <small>
                                                {detail?.renteeksempel}
                                            </small>
                                        </p>
                                    </div>
                                </div>
                                <div className="features">
                                    <p>Maks kreditt: <span>{detail?.maxcredit}</span></p>
                                    <p>Rentefrie dager: <span>{detail?.interestfreedays}</span></p>
                                    <p>Aldergrense: <span>{detail?.age}</span></p>
                                    <p>Effektiv rente: <span>{detail?.effectiverent}</span></p>
                                </div>
                                <div className="benefits">
                                    {detail?.benefits &&
                                        detail.benefits.map(x => (
                                            <p>
                                                {x?.hasbenefit ? (
                                                    <CheckIcon sx={{color: '#65bf7f'}} />
                                                ) : (
                                                    <CloseIcon sx={{color: '#D96363'}} />
                                                )}
                                                <span>{x?.benefit}</span>
                                            </p>
                                        ))
                                    }
                                </div>
                                <div className="buttons">
                                    <a href={detail?.buttonlink} target="_blank">
                                        <small>Annonselenke</small>
                                        <Button variant="outlined">
                                            {detail?.buttontext}
                                            <ArrowForwardIcon sx={{ ml: 1 }} />
                                        </Button>
                                    </a>
                                    {detail?.altbuttontext && (
                                        <>
                                        <a href={detail?.buttonlink} target="_blank">
                                            <Button variant="outlined" className="alt">
                                                {detail?.altbuttontext}
                                                <ArrowForwardIcon sx={{ ml: 1 }} />
                                            </Button>
                                        </a>
                                        </>
                                    )}
                                </div>
                            </div>
                        </>
                    ))}
                </div>

        </>
    );
}