/**
 * Description: Reducer's State and Handler for Authentication purposes using Redux Store
 */
import { AUTH_ACTIONS_TYPES } from "../actions/auth";

const initialState = {
    user: {},
    authuser: {},
    creditCheck: {},
    debtRegister: {},
    lastLoginData: null,
    email: null,
    phoneNumber: null,
    totalExistingDebt: null,
    sumToRefinance: null,
    amountExitingLoans: null,
    extraLoanAmount: null,
    loanAmountToApplyFor: null,
    estateValue: null,
    remainingMortgage: null,
    monthlyMortgageLoan: null,
    monthlyRent: null,
    boforhold: null,
    signicat: null
    /*Any other data can go here as well. Note: The user object is the object from SigniCat Login */
};

const reducer = (
    state = initialState,
    action = { type: "", payload: null }
) => {
    switch (action.type) {
        case AUTH_ACTIONS_TYPES.AUTH_LOGIN: {
            return {
                ...state,
                user: (action.payload && action.payload.user) || "",
                authuser: (action.payload && action.payload.authuser) || "",
                creditCheck:
                    (action.payload && action.payload.creditCheck) || "",
                lastLoginData: Date.now(),
            };
        }
        case AUTH_ACTIONS_TYPES.AUTH_END_SESSION: {
            return {
            };
        }
        case AUTH_ACTIONS_TYPES.AUTH_UPDATE_SESSION: {
            return {
                ...state,
                ...action.payload,
            };
        }
        default: {
            return state;
        }
    }
};
export default reducer;
