/**
 * Description: Action's Types and Creators for Deals purposes using Redux Store
 */
export const DEALS_ACTIONS_TYPES = {
    SET_DEALS: "SET_DEALS",
    CLEAR_DEALS: "CLEAR_DEALS",
    //TODO: Add more types regarding the desired or intended logic
};

//TODO: Add here action creators if needed in order to permute complex data

export const setDealsActionCreator = (payload) => {
    return {
        type: DEALS_ACTIONS_TYPES.SET_DEALS,
        payload,
    };
};
