/**
 * Description: Exports handlers or utilities to be used in Debt Register calculations
 */
const round = (x) => {
    return Math.round(x * 100) / 100;
};

/*********
 * Exporing functions
 *********/

const calculateLoanCost = (loanAmount, downPaymentTimeYears, interestRate) => {
    const principal = loanAmount;
    const interest = interestRate / 100 / 12;
    const payments = downPaymentTimeYears * 12;
    const x = Math.pow(1 + interest, payments);
    let monthly = (principal * x * interest) / (x - 1);
    monthly = parseInt(monthly);
    if (
        !isNaN(monthly) &&
        monthly !== Number.POSITIVE_INFINITY &&
        monthly !== Number.NEGATIVE_INFINITY
    ) {
        return {
            monthly: round(monthly),
            total: round(monthly * payments),
        };
    }
};

const convertLoanTypeText = (data) => {
    return {
        creditFacility: "Kredittkort / handlekonto",
        repaymentLoan: "Forbrukslån",
        chargeCard: "Faktureringskort",
    }[data];
};

const formatInterestRate = (data) => {
    return parseInt(data) > 0
        ? [
              data?.slice(0, data?.length - 2),
              ".",
              data?.slice(data?.length - 2),
          ]?.join("")
        : 0;
};

const parseDebtRegisterLoanAmount = (data) => {
    let loanAmount =
        parseFloat(data?.balance) > 0
            ? parseFloat(data?.balance)
            : parseFloat(data?.interestbearingbalance) +
                  parseFloat(data?.noninterestbearingbalance) ||
              parseFloat(data?.creditlimit);
    let parsedLoanAmount = loanAmount
        ?.toString()
        ?.substring(0, loanAmount?.toString()?.length - 2);
    return parsedLoanAmount;
};

const parseTerms = (data) => {
    const terms = parseInt(data) > 0 ? parseInt(data) / 12 : 10;
    return terms;
};

const removeLoanFromArray = (array, index, setLoanState) => {
    let newArr = [...array];
    newArr.splice(
        array.findIndex((el) => el.index === index),
        1
    );
    setLoanState(newArr);
};

const addLoanToArray = (array, index, data, setLoanState) => {
    let newArr = [...array];
    newArr.push({ index: index, loan: data });
    setLoanState(newArr);
};

const formatLoanAmount = (type, data) => {
    let loanAmount = "";
    if (type === "total") {
        loanAmount = data?.reduce(function (prev, cur) {
            if (parseFloat(cur?.loan?.balance) > 0) {
                return prev + parseFloat(cur?.loan?.balance);
            }
            return (
                prev +
                (parseFloat(cur?.loan?.interestbearingbalance) +
                    parseFloat(cur?.loan?.noninterestbearingbalance))
            );
        }, 0);
    } else if (type === "savingAmount") {
        loanAmount = data;
    } else {
        if (parseFloat(data?.balance) > 0) {
            loanAmount = parseFloat(data?.balance);
        }

        if (
            parseFloat(data?.interestbearingbalance) +
                parseFloat(data?.noninterestbearingbalance) >
            0
        ) {
            loanAmount =
                parseFloat(data?.interestbearingbalance) +
                parseFloat(data?.noninterestbearingbalance);
        }

        if (parseFloat(data?.creditlimit) > 0) {
            loanAmount = parseFloat(data?.creditlimit);
        }
    }
    let loanAmountOutput = loanAmount?.toString().slice(0, -2);
    return parseFloat(loanAmountOutput) > 0 ? loanAmountOutput : "0";
};
export {
    calculateLoanCost,
    convertLoanTypeText,
    formatInterestRate,
    parseDebtRegisterLoanAmount,
    parseTerms,
    removeLoanFromArray,
    addLoanToArray,
    formatLoanAmount,
};
