
import React, { useEffect, useState } from "react";
import sanityClient from "../client.js";
import Header from './Header';
import Footer from './Footer';
import Visalogo from "../assets/visalogo.png";
import Mastercardlogo from "../assets/mastercardlogo.png";
import {Helmet} from "react-helmet";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import RecommendIcon from '@mui/icons-material/Recommend';

export default function Creditcard() {

    const [creditData, setCreditData] = useState([]);
    const [heroData, setHeroData] = useState();

    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "creditcards"] | order(order asc) {
                    name,
                    cardtype, 
                    maxcredit,
                    interestfreedays,
                    age,
                    effectiverent,
                    renteeksempel,
                    buttonlink,
                    boost,
                    buttontext,
                    altbuttontext,
                    benefits[]{
                        hasbenefit, 
                        benefit,
                    },
                    image{
                        asset->{
                            url,
                        }
                    }
            }`
            )
            .then((data) => setCreditData(data))
            .catch(console.error);
    }, []);
    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "althero"][0]{
                    heading,
                    ingress,
                    list,
                    buttontext,
                    buttonlink,
                    image{
                    asset->{
                        url,
                        }
                    }
            }`
            )
            .then((data) => setHeroData(data))
            .catch(console.error);
    }, []);

    return (
        <>
        <Helmet>
            <title>Kredittkort | Pengelegen</title>
            <meta name="desciption" content="Her kan du se og sammenlikne de beste kredittkortene på markedet" />
            <link rel="canonical" href={window.location.href} />
        </Helmet>
        <Header />
            <div className="hero-section alt">
                <div className="container">
                    <div className="inner-hero">
                        <div className="left">
                            <h1>{heroData?.heading}</h1>
                            <p className="lead">{heroData?.ingress}</p>
                        </div>
                        <div className="right">
                            <img src={heroData?.image.asset.url} alt="right-icon" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="creditcards">
                    {creditData &&
                    creditData.map((detail, index) => (
                        <>
                            <div className={detail?.boost ? 'single-creditcard boost' : 'single-creditcard'}>
                                {detail.boost && (
                                    <>
                                        <div className="chip-holder">
                                            <Chip icon={<RecommendIcon />} label="Anbefalt!" color="success" />
                                        </div>
                                    </>
                                )}
                                <div className="main-info">
                                    <img src={detail?.image.asset.url} />
                                    <div className="details">
                                        {!detail?.cardtype ? (
                                            <img src={Visalogo} />
                                        ) : (
                                            <img src={Mastercardlogo} />
                                        )}
                                        <h3>{detail?.name}</h3>
                                        <p>
                                            <small>
                                                {detail?.renteeksempel}
                                            </small>
                                        </p>
                                    </div>
                                </div>
                                <div className="features">
                                    <p>Maks kreditt: <span>{detail?.maxcredit}</span></p>
                                    <p>Rentefrie dager: <span>{detail?.interestfreedays}</span></p>
                                    <p>Aldergrense: <span>{detail?.age}</span></p>
                                    <p>Effektiv rente: <span>{detail?.effectiverent}</span></p>
                                </div>
                                <div className="benefits">
                                    {detail?.benefits &&
                                        detail.benefits.map(x => (
                                            <p>
                                                {x?.hasbenefit ? (
                                                    <CheckIcon sx={{color: '#65bf7f'}} />
                                                ) : (
                                                    <CloseIcon sx={{color: '#D96363'}} />
                                                )}
                                                <span>{x?.benefit}</span>
                                            </p>
                                        ))
                                    }
                                </div>
                                <div className="buttons">
                                    <a href={detail?.buttonlink} target="_blank">
                                        <small>Annonselenke</small>
                                        <Button variant="outlined">
                                            {detail?.buttontext}
                                            <ArrowForwardIcon sx={{ ml: 1 }} />
                                        </Button>
                                    </a>
                                    {detail?.altbuttontext && (
                                        <>
                                        <a href={detail?.buttonlink} target="_blank">
                                            <Button variant="outlined" className="alt">
                                                {detail?.altbuttontext}
                                                <ArrowForwardIcon sx={{ ml: 1 }} />
                                            </Button>
                                        </a>
                                        </>
                                    )}
                                </div>
                            </div>
                        </>
                    ))}
                </div>
            </div>
        <Footer />
        </>
    );
}