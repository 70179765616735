const _wait = async (time) => {
  return new Promise(($resolve) => {
    setTimeout(() => {
      $resolve(true);
    }, time);
  });
}
export const getContactIDHubspot = async (ssn) => {
  return new Promise(async ($resolve) => {
    const REQUEST_CONFIG = {
      retryAttempts: 10,
      timeout: 5000
    };
    for (let attempt = 1; attempt <= REQUEST_CONFIG.retryAttempts; attempt++) {
      await fetch(`https://backend.mileni.no/api/hubspot/user/${ssn}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json()).then(async (res) => {
          if (res?.data?.response?.email) {
            $resolve(res);
          } else {
            console.log("Failed to get data User-Contact ::: ", attempt);
            await _wait(REQUEST_CONFIG.timeout);
          }
        })
        .catch((error) => {
          console.error("Failed to fetch -DebtRegister Data-", error);
        });
    }
    $resolve({ emptyData: true })
  });
};
export const getDebtRegisterToken = async (signicatToken) => {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({
      token: signicatToken,
    }),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  };
  return fetch(
    "https://backend.mileni.no/api/gjeldsregister/connection?app=moneydoctor",
    requestOptions
  )
    .then((res) => res.json())
    .catch((error) => {
      console.error("Failed to fetch -DebtRegister Data-", error);
    });
};
export const getDebtRegisterData = async (hubspotContactId) => {
  try {
    const rawResponse = await fetch(
      "https://backend.mileni.no/api/gjeldsregister/debt-data",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ contactId: hubspotContactId }),
      }
    );
    const content = await rawResponse.json();
    // Set original loan amount and total count of loans in Redux
    let loanAmountArr = [];
    let amountOfLoan = content?.data?.response?.customer?.loan?.length;
    content?.data?.response?.customer?.loan.map((data, index) => {
      if (
        parseInt(data?.interestbearingbalance) > 0 ||
        parseInt(data?.noninterestbearingbalance) > 0 ||
        data?.balance
      ) {
        //newArr.push({ index: index, loan: data });
        loanAmountArr.push({ index: index, loan: data });
      }
    });
    return {
      data: { ...content?.data?.response, _amountOfLoan: amountOfLoan },
    };
  } catch (error) {
    return {
      error,
      message: "Failed to acquire user's data from Debt Register",
    };
  }
};
export const updateLeadsOfContact = async (payload) => {
  return fetch(`https://backend.mileni.no/api/hubspot/leads`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      formguid: "387dd218-9005-4d8a-897c-011cf05332b5"
    },
    body: JSON.stringify({
      ...payload,
    }),
  })
    .then((res) => res.json())
    .catch((error) => {
      //TODO:Add debbuger environment to display
      console.error("Failed to execute endpoint with success >>> ", error);
    });
};
