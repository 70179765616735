import Header from './Header';
import Footer from './Footer';
import { useEffect } from 'react';
import {Helmet} from "react-helmet";

export default function Personvern() {
    useEffect(() => {
        window.scrollTo(0, 0)
    })
  return (
    <>
    <Helmet>
            <title>Personvernerklæring | Pengelegen</title>
            <meta name="desciption" content="Denne personvernerklæringen gjelder for alle som benytter seg av tjenester fra Mileni AS . For at tjenestene våre skal fungere, trenger vi noen personopplysninger fra deg. I tillegg vil vi innhente kredittinformasjon. Disse opplysningene blir sikret og behandlet i tråd med gjeldende personvernlovgivning, og du kan når som helst si opp avtalen. Da sletter vi alle dine opplysninger fra våre registre. I denne personvernerklæringen forklarer vi hvilke personopplysninger vi samler inn, hvordan vi samler dem, hva vi skal bruke dem til og hvordan vi oppbevarer opplysningene dine. Har du spørsmål rundt personopplysninger? Kontakt oss på telefon: 22 42 22 22 eller e-post post@mileni.no." />
            <link rel="canonical" href={window.location.href} />
        </Helmet>
    <Header />
        <div className="singlepost" style={{marginTop: '50px'}}>
            <div className="post-container">
            <div class="inner">
            <h1>Personvernerklæring</h1>
            <p>Denne personvernerklæringen gjelder for alle som benytter seg av tjenester fra Mileni AS . For at tjenestene våre skal fungere, trenger vi noen personopplysninger fra deg. I tillegg vil vi innhente kredittinformasjon. Disse opplysningene blir sikret og behandlet i tråd med gjeldende personvernlovgivning, og du kan når som helst si opp avtalen. Da sletter vi alle dine opplysninger fra våre registre. I denne personvernerklæringen forklarer vi hvilke personopplysninger vi samler inn, hvordan vi samler dem, hva vi skal bruke dem til og hvordan vi oppbevarer opplysningene dine. Har du spørsmål rundt personopplysninger? Kontakt oss på telefon: 22 42 22 22 eller e-post post@mileni.no.</p>
            <h3>Hvordan samler vi inn opplysninger?</h3>
<p>De fleste opplysningene oppgis frivillig av deg i vårt nettskjema eller per telefon. I tillegg henter vi inn enkelte opplysninger ved søk på fødsels -og personnummer. Noen tjenesteleverandører, som for eksempel bankpartnere, krever at det innhentes mer omfattende dokumentasjon om deg. Denne innhentingen gjøres dels av Mileni AS og dels av våre tjenesteleverandører.</p>
<h3>Hva slags informasjon samler vi inn om deg?</h3>
<p>For å kunne lage et tilbud best mulig tilpasset dine behov, trenger vi følgende personopplysninger om deg: navn og adresse, kredittopplysninger, fødsels- og personnummer, familieforhold, gjeld og inntektsopplysninger, informasjon om utdannelse og arbeidsgiver, statsborgerskap og kontonummer. Når du bruker vår nettside, samler vi informasjon gjennom informasjonskapsler (cookies). Dette kan for eksempel være hvilken mobil og nettleser du bruker, hvilket tredjeparts-nettsted du har kommet inn på siden vår via, hvilke spesifikke sider du besøker inne på nettsiden vår, hvor lenge besøket varer, IP-adressen din og annen informasjon om datamaskinen din. Dersom du ikke vil dele denne informasjonen kan du lese mer om dette lenger ned i personverærklæringen. Vi ber alltid om samtykke for innhenting av informasjon.</p>
<h3>Hva bruker vi informasjonen til?</h3>
<p>Personopplysningene du oppgir eller gir oss samtykke til å innhente, brukes til å utforme personlig tilpassede tilbud til deg. I tillegg bruker vi informasjonen til å utvikle enda bedre tjenester i fremtiden. Dette bruker vi dine opplysninger til:</p>
<ul>
<li>Å behandle din lånesøknad. I denne sammenheng gjør vi også en kredittsjekk av deg.</li>
<li>Å kunne kontakte deg underveis i søknadsprosessen, enten på telefon, e-post eller SMS.</li>
<li>Å la våre partnerbanker kontakte deg underveis i søknadsprosessen, enten på telefon, e-post eller SMS.</li>
<li>Å kunne tilpasse tjenestene, kommunikasjonen og forslagene våre til deg.</li>
<li>Å føre intern statistikk, slik at vi kan utvikle og forbedre tjenestene våre.</li>
<li>Å drive markedsføring, og til å kunne opplyse om nye tilbud og tjenester som kan være av interesse for deg.</li>
</ul>
<h3>Hvordan behandler vi dine personopplysninger?</h3>
<p>Mileni er behandlingsansvarlig for de personopplysninger som samles inn om deg i tråd med denne personvernerklæringen. Vi setter våre kunders personvern høyt og forsikrer at ditt personvern ivaretas i overensstemmelse med de krav som stilles i gjeldende lovgivning. Herunder forsikrer Mileni AS at vi lagrer og ivaretar dine opplysningene på en sikker måte i henhold til enhver tids gjeldende personvernlovgivning samt at opplysningene ikke vil komme uvedkommende til kjennskap. All informasjon om deg lagres på servere og tjenester som er sertifisert for behandling av sensitive personopplysninger. Vi lagrer dine opplysninger uten personlig identifiserbar informasjon og benytter kun ikke-sensitiv informasjon når vi utarbeider interne rapporter. Vi benytter informasjon om din bruk av våre nettsider for å forstå bruksmønstre og forbedre våre tjenester, men anonymiserer da dine personopplysninger. Det innebærer at rapportene i utgangspunktet ikke inneholder informasjon som kan knyttes direkte til enkeltpersoner. IP-adresser kan likevel i enkelte tilfeller benyttes til å lage rapporter basert på geografiske kriterier. Mileni AS pålegger sine ansatte og tilknyttede avtaleparter å følge de lover, forskrifter og interne retningslinjer som gjelder for behandling av personopplysninger.</p>
<h3>Hvem deler vi dine personopplysninger med?</h3>
<p>Mileni AS deler bare personopplysningene dine med andre virksomheter når det er nødvendig for å oppfylle formålet med behandlingen av dine personopplysninger. For eksempel må vi dele personopplysninger med tredjeparter som bankpartnere og andre tjenesteleverandører for å kunne oppfylle avtalen med deg. Bankene kan kun benytte opplysningene for å vurdere om de vil gi lånetilbud og for å stille nærmere betingelser for låneopptak. Banken er da ansvarlig for behandling av personopplysninger ved vurdering av lånesøknad. Bankens håndtering av dine personopplysninger vil følge bankenes egne personvernerklæringer. Leverandører kan kun bruke personopplysningene til de formål de er innhentet for. Vi deler også din kontaktinformasjon med våre tekniske systemleverandører. Disse fremgår av oversikten nedenfor.</p>
<ul>
<li><strong>Hubspot:</strong> Vi benytter Hubspot for behandling av kundeopplysninger og til å føre statistikk.</li>
<li><strong>Google Analytics:</strong>&nbsp;Opplysningene behandles for å forstå hvem som bruker nettsiden vår og hvordan de bruker den.</li>
<li><strong>Creditsafe AS:</strong>&nbsp;Behandler personopplysninger for å foreta kredittsjekk og sende gjenpartsbrev.</li>
<li><strong>Google Inc.:</strong>&nbsp;Lagrer personopplysningene i forbindelse med drift av e-post-tjenester.</li>
<li><strong>Trustpilot.com:</strong>&nbsp;Benytter opplysningene til å sende deg e-post på våre vegne.</li>
<li><strong>Facebook Inc.:</strong>&nbsp;Benytter opplysningene for å tilby chat-dialog, og til å målrette reklame mot deg på våre vegne.</li>
<li><strong>Hotjar:</strong>&nbsp;Benytter opplysningene for å forstå brukeradferd og forbedre din brukeropplevelse.</li>
<li><strong>Adtraction:</strong>&nbsp;Benytter opplysningene for å målrette markedsføring og føre statistikk.</li>
<li><strong>Adservice:</strong>&nbsp;Benytter opplysningene for å målrette markedsføring og føre statistikk.</li>
<li><strong>Gjeldsregisteret.com:</strong>&nbsp;Løsningen skal sørge for at samtykke gis, og kan trekkes tilbake, i tråd med reglene i gjeldende personopplysningslov og gjeldsinformasjonsloven, derunder, men ikke begrenset til kravene til informasjon til privatpersoner.</li>
</ul>
<p>Deling av personopplysninger vil også gjøres når det er pålagt etter lov eller forskrift, eller dersom det pålegges med grunnlag i en rettskraftig avgjørelse. Deling utover det nevnte vil ikke gjøres uten at du særskilt samtykker til det. Databehandlerne våre er bundet av vår databehandleravtale. De kan ikke benytte dine opplysninger til egne formål eller formål som strider mot formålet angitt i denne personvernerklæringen.</p>
<h3>Dine rettigheter som kunde hos oss</h3>
<p>Du har rett på tilgang til personopplysninger registrert om deg. Du kan ved skriftlig og undertegnet henvendelse til oss kreve innsyn i registrerte personopplysninger, beskrivelse av hvilke typer opplysninger som behandles og nærmere informasjon om vår behandling av opplysningene. Du har rett til å kreve at vi retter feil i dine personopplysninger. Du har rett på sletting eller begrenset behandling av personopplysninger dersom du mistenker at opplysningene blir behandlet ulovlig eller ikke er korrekte. Du har rett til å nekte bruk av dine personopplysninger i direkte markedsføring og i annen kommunikasjon. Dette omfatter også retten til å trekke tilbake samtykke om bruk av personopplysninger. Du har rett til når som helst å kalle tilbake samtykke til at Mileni kan behandle dine personopplysninger. Du har rett til å bli glemt. Det vil si at all informasjon vi har om deg slettes. Du har rett til dataportabilitet, det vil si å få utlevert de personopplysningene som du selv har bidratt med.</p>
<h3>Hvor lenge vil dine opplysninger lagres?</h3>
<p>Mileni AS lagrer ikke personopplysninger lenger enn det som er nødvendig og lovlig. Vi sletter som hovedregel personopplysninger knyttet til potensielle kunder etter at vi ikke har kontaktet vedkommende på et år, eller når den potensielle kunden trekker tilbake sitt samtykke.</p>
<h3>Om informasjonskapsler (cookies)</h3>
<p>Når du besøker vår nettside, bruker vi ulike teknologier for å gjenkjenne deg som bruker. Vi bruker informasjonskapsler til å analysere og teste hvordan brukerne reagerer på endringer av nettsidene. Informasjonskapsler kan også gi informasjon om feilsaker og gir oss mulighet til å måle effekten av våre markedsføringskampanjer.</p>
<h3>Slik forhindrer du at informasjonskapsler lagres</h3>
<p>Du kan slette informasjonskapsler fra din nettleser når som helst. Dette vil imidlertid gjøre at dine personlige innstilinger på nettsiden forsvinner. Du kan også endre innstillingene i nettleseren din, slik at den ikke tillater at informasjonskapsler lagres, eller at du manuelt må godkjenne hver enkelt informasjonskapsel. Dette kan gi dårligere funksjonalitet på visse nettsider og forhindre tilgang til sider som krever innlogging. Du kan reservere deg mot informasjonskapsler som gir deg markedsføring basert på interesseområder på reservasjonssiden til Network Advertising Initiative.</p>
<h3>Endringer i personvernerklæringen</h3>
<p>Denne personvernerklæringen vil oppdateres med jevne mellomrom for å reflektere endringer i tjenestene selskapet tilbyr. Dersom vi gjør omfattende endringer vil du få beskjed per e-post, slik at du alltid er oppdatert.</p>
        </div>
            </div>
        </div>
        <Footer location={"agreement"} />
    </>
  );
}