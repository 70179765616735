import { useState, useEffect } from 'react'
import sanityClient from "../client.js";
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { authUpdateSessionActionCreator } from "../store/actions/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
export default function Footer({ location }) {
    const dispatch = useDispatch();
    const [footerData, setFooterData] = useState([]);
    const navigate = useNavigate();
    
    const openLoginForm = () => {
        dispatch(
            authUpdateSessionActionCreator({
                isLoginFormOpened: true
            })
        );
        if (location) {
            navigate("/");
        }
    };

    useEffect(() => {
        return () => {
            dispatch(
                authUpdateSessionActionCreator({
                    isLoginFormOpened: false
                })
            );
        }
    }, []);

    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "options"][0]{
                mainlogo,
                mainimage, 
                footerheading,
                ingress,
                buttontext,
                buttonlink,
                footercopy,
                mainimage{
                asset->{
                    url,
                    }
                },
                mainlogo{
                asset->{
                    url,
                    }
                }
            }`
            )
            .then((data) => setFooterData(data))
            .catch(console.error);
    }, []);


    /* Dersom kategori ikke er valgt */
    return (
        <>
            <div className="container">
                {window.location.pathname != "/loanlist" &&
                    <div className="footer-extension">
                        <div className="inner">
                            <div className="left">
                                <h2>{footerData?.footerheading}</h2>
                                <p>{footerData?.ingress}</p>
                                <Button onClick={() => openLoginForm()} variant="outlined">
                                    {footerData?.buttontext}
                                    <ArrowForwardIcon sx={{ ml: 1 }} />
                                </Button>
                            </div>
                            <div className="right">
                                <img src={footerData?.mainimage?.asset?.url} />
                            </div>
                        </div>
                    </div>
                }
            </div>
            <footer>
                <div className="container">
                    <div className="inner-footer">
                        <div className="logo-holder">
                            <Link to="/">
                                <img src={footerData?.mainlogo?.asset?.url} />
                            </Link>
                        </div>
                        <div className="footer-text">{footerData?.footercopy}</div>
                    </div>
                </div>
                <p className="helvete">
                    Pengelegen er en tjeneste fra Mileni. Tjenesten formidler
                    hverken legetjenester eller finansiell rådgivning. Navnet
                    spiller på økonomisk helse.
                </p>
                <p className="flex justify-center mt-5 small">
                    <Link className="mr-2 underline" to="/personvern">Personvernerklæring & Cookies</Link> -&nbsp;&nbsp;
                    <Link className="mr-2 underline" to="/wordcloud">Ordsky</Link>
                </p>
            </footer>
        </>
    );
}