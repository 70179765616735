/**
 * Description: Action's Types and Creators for Support purposes using Redux Store
 */
export const SUPPORT_ACTIONS_TYPES = {
    SET_GDPRVISIBILITY: "SET_GDPRVISIBILITY",
    //TODO: Add more types regarding the desired or intended logic
};

//TODO: Add here action creators if needed in order to permute complex data

export const setGDPRVisibilityActionCreator = (payload) => {
    return {
        type: SUPPORT_ACTIONS_TYPES.SET_GDPRVISIBILITY,
        payload,
    };
};
