/**
 * Description: Provides Single Loan's Information as Component
 */
/* eslint-disable array-callback-return */
import Header from "./Header";
import Footer from "./Footer";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { ReactComponent as SwineGood } from "../assets/svinbra.svg";
import { ReactComponent as SwineBad } from "../assets/svindarlig.svg";
import { ReactComponent as SwineOk } from "../assets/svinok.svg";
import { useDispatch } from "react-redux";
import { authUpdateSessionActionCreator } from "../store/actions/auth";
import SavingsIcon from "@mui/icons-material/Savings";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Form from "./Form";
import {
  calculateLoanCost,
  formatInterestRate,
  parseDebtRegisterLoanAmount,
  parseTerms,
  removeLoanFromArray,
  addLoanToArray,
  formatLoanAmount,
} from "../utils/debtRegister";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CreditCardComponent from "./CreditcardCompontent";
import { updateLeadsOfContact } from "../utils/mileniApi";
import CreditCardIcon from '@mui/icons-material/CreditCard';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SingleLoan = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const dispatch = useDispatch();
  const [active, setActive] = useState([]);
  const storeAuth = useSelector((state) => state.auth); //Authentication Data from Redux's Store (User)
  const [selectedLoans, setSelectedLoans] = useState([]);
  const [totalSaving, setTotalSaving] = useState(0);
  const [accordion, setAccordion] = useState(false);
  const [loanAmounts, setLoanAmounts] = useState({
    sumToRefinance: 0,
    totalLoanAmount: 0,
  });
  useEffect(() => {
    dispatch(
      authUpdateSessionActionCreator({
        loanListRedirectExpected: false,
      })
    );
    setTimeout(() => {
      window.scroll({ top: 0, behavior: "smooth" });
    }, 100);
  }, []);
  // Init the Active array

  useEffect(() => {
    if (storeAuth?.debtRegisterData) {
      setActive(
        Array(storeAuth?.debtRegisterData?.customer?.loan.length).fill(true)
      );
      const _selectedLoans = [];
      storeAuth?.debtRegisterData?.customer?.loan.map((data, index) => {
        if (
          parseInt(data?.interestbearingbalance) > 0 ||
          parseInt(data?.noninterestbearingbalance) > 0 ||
          data?.balance
        ) {
          _selectedLoans.push({ index: index, loan: data });
        }
        //console.log("Nominal Interest Rate :::  ", formatInterestRate(data?.nominalinterestrate));
      });
      setSelectedLoans(_selectedLoans);
    }
  }, [storeAuth?.debtRegisterData]);
  useEffect(() => {
    let _tempSavings = [];
    if (selectedLoans.length >= 1) {
      selectedLoans?.map((data) => {
        _tempSavings.push(
          calculateLoanCost(
            parseDebtRegisterLoanAmount(data?.loan),
            parseTerms(data?.loan?.terms),
            formatInterestRate(data?.loan?.nominalinterestrate)
          )?.total -
            calculateLoanCost(
              parseDebtRegisterLoanAmount(data?.loan),
              parseTerms(data?.loan?.terms),
              7
            )?.total >
            0
            ? calculateLoanCost(
                parseDebtRegisterLoanAmount(data?.loan),
                parseTerms(data?.loan?.terms),
                formatInterestRate(data?.loan?.nominalinterestrate)
              )?.total -
                calculateLoanCost(
                  parseDebtRegisterLoanAmount(data?.loan),
                  parseTerms(data?.loan?.terms),
                  7
                )?.total
            : 0
        );
      });
      setTotalSaving(_tempSavings.reduce((a, b) => a + b, 0));
      setLoanAmounts({
        //Calculate SumToRefinance
        sumToRefinance: formatLoanAmount("total", selectedLoans),
        //Calculate Total Loan Amount
        totalLoanAmount: formatLoanAmount("total", selectedLoans),
      });
      //Trigger this information to hubspot, in order to get the details of the user in case he or she does not send a form
      updateLeadsOfContact({
        ssn: `${storeAuth?.signicat?.userData?.profile?.nin}`,
        firstname: `${storeAuth?.signicat?.userData?.profile?.given_name}`,
        lastname: `${storeAuth?.signicat?.userData?.profile?.family_name}`,
        email: storeAuth?.user?.email,
        mobilephone: storeAuth?.user?.phone,
        total_belop_usikret_gjeld: formatLoanAmount("total", selectedLoans),
      });

      dispatch(
        authUpdateSessionActionCreator({
          calculatedUserLoans: {
            hasLoans: true,
            totalLoanAmount: formatLoanAmount("total", selectedLoans),
            sumToRefinance: formatLoanAmount("total", selectedLoans),
            totalLoans: selectedLoans.length,
          },
        })
      );
    } else {
      dispatch(
        authUpdateSessionActionCreator({
          calculatedUserLoans: {
            hasLoans: false,
            totalLoanAmount: 0,
            sumToRefinance: 0,
            totalLoans: 0,
          },
        })
      );
    }
  }, [selectedLoans]);

  useEffect(() => {
    if (storeAuth?.hubspotContactId) {
      console.log("Dette er hele store auth :::: ", storeAuth);
      if (storeAuth?.utm?.utm_source?.value === "adservice") {
        fetch(
          `https://www.aservice.cloud/trc/mastertag/conv?asclid=${storeAuth?.utm?.asclid?.value}&order_id=${storeAuth?.hubspotContactId}`
        ).then((result) => {
          console.log("Har sendt fetch på denne ::: ", result);
        });
      }
    }
  }, [storeAuth?.hubspotContactId]);

  return (
    <>
      <Header />
      <Helmet>
        <script type="application/javascript">{`
        if(storeAuth?.utm?.utm_source?.value === "adservice") {
          console.log("Helllo nå skal ting kunnde trackes ::: ", atag);
          atag('track', 'conversion', {order_id : storeAuth?.hubspotContactId});
        }
    `}</script>
      </Helmet>
      {storeAuth?.isFetchingDebtData &&
        (!storeAuth?.debtRegisterData?.customer?.customerid ||
          !storeAuth?.debtRegisterData?.customer?.customerid) && (
          <>
            <div className="loading-holder">
              <span class="loader"></span>
            </div>
          </>
        )}
      <div className="container">
        <Box sx={{ width: "100%", padding: "5vw 0", minHeight: "70vh" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                icon={<SavingsIcon />}
                iconPosition="start"
                label="Min gjeld"
                {...a11yProps(0)}
              />
              <Tab
                icon={<CreditCardIcon />}
                iconPosition="start"
                label="Kredittkort"
                {...a11yProps(0)}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <div className="loan-list">
              <div className="left">
                {storeAuth?.debtRegisterData?.customer?.loan.map(
                  (data, index) => {
                    if (
                      parseInt(data?.interestbearingbalance) > 0 ||
                      parseInt(data?.noninterestbearingbalance) > 0 ||
                      data?.balance
                    ) {
                      return (
                        <div className="single-loan" key={index}>
                          <div className="info">
                            <div className="top">
                              <div className="name">
                                <h3>{data?.financialinstitutionname}</h3>
                              </div>
                              {(formatInterestRate(
                                data?.nominalinterestrate
                              ) === 0 ||
                                !data?.nominalinterestrate) && (
                                <>
                                  <div className="rentescore pos">
                                    Rentescore:
                                    <SwineGood />
                                    Svinbra!
                                  </div>
                                </>
                              )}
                              {formatInterestRate(data?.nominalinterestrate) >
                                11 && (
                                <>
                                  <div className="rentescore pos">
                                    Rentescore:
                                    <SwineBad />
                                    På trynet!
                                  </div>
                                </>
                              )}
                              {formatInterestRate(data?.nominalinterestrate) >=
                                8 &&
                                formatInterestRate(data?.nominalinterestrate) <=
                                  10 && (
                                  <>
                                    <div className="rentescore pos">
                                      Rentescore:
                                      <SwineOk />
                                      Greit nok!
                                    </div>
                                  </>
                                )}
                            </div>
                            <div className="bottom">
                              <div className="data-info">
                                <p>Nominell rente</p>
                                <span>
                                  {formatInterestRate(
                                    data?.nominalinterestrate
                                  )}{" "}
                                  %
                                </span>
                              </div>
                              <div className="data-info">
                                <p>Lånebeløp</p>
                                <span>
                                  {parseInt(
                                    formatLoanAmount("single", data)
                                  ).toLocaleString("no-NO")}
                                  <small> kr</small>
                                </span>
                              </div>
                              <div className="data-info">
                                <h4>Du kan spare</h4>
                                <span>
                                  {calculateLoanCost(
                                    parseDebtRegisterLoanAmount(data),
                                    parseTerms(data?.terms),
                                    formatInterestRate(
                                      data?.nominalinterestrate
                                    )
                                  )?.total -
                                    calculateLoanCost(
                                      parseDebtRegisterLoanAmount(data),
                                      parseTerms(data?.terms),
                                      7
                                    )?.total >
                                  0
                                    ? (
                                        calculateLoanCost(
                                          parseDebtRegisterLoanAmount(data),
                                          parseTerms(data?.terms),
                                          formatInterestRate(
                                            data?.nominalinterestrate
                                          )
                                        )?.total -
                                        calculateLoanCost(
                                          parseDebtRegisterLoanAmount(data),
                                          parseTerms(data?.terms),
                                          7
                                        )?.total
                                      ).toLocaleString("no-NO")
                                    : 0}
                                  <small> kr</small>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  }
                )}
                {/*
                <a
                  className="only-mob"
                  target="_blank"
                  href="https://www.unofinans.no/refinansiering/?utm_content=1002&p=1705643586&utm_medium=affiliate&utm_campaign=adtraction&uf=1002&at_gd=BC013124281A1C53386468C7BC1393EE92B55825"
                >
                  <Button variant="filled">
                    Refinansier gjelden nå
                    <ArrowForwardIcon />
                  </Button>
                </a>
                */}
              </div>
              <div className="right">
                <div className="box">
                  <p className="pb-4">Min gjeld er i dag:</p>
                  <h3>
                    {" "}
                    {parseInt(loanAmounts.sumToRefinance).toLocaleString([
                      "ban",
                      "id",
                    ])}{" "}
                    kr
                  </h3>
                  <p className="pb-4">Vi kan hjelpe deg å spare opptil:</p>
                  <h3>
                    <span> {totalSaving.toLocaleString(["ban", "id"])} kr</span>
                  </h3>
                  <Form />
                  {/*
                  <p className="small p-4">
                    Søk hos vår foretrukne partner Axo Finans. De er etter
                    pengelegens oppfatning dagens beste på refinansiering
                  </p>
                  */}
                  <p style={{marginTop: '15px'}} className="small">
                    Eks: Nom. rente 11,66 %, eff. rente 13,21 %, 150 000 kr o/5
                    år, kostnad 52 007 kr, totalt 202 007 kr.
                  </p>
                </div>
              </div>
            </div>
          </TabPanel>
          
                <TabPanel value={value} index={1}>
                    <CreditCardComponent />
                </TabPanel>
                
        </Box>
      </div>
      {/* Container */}
      <Footer location={"single-loan"} />
    </>
  );
};

export default SingleLoan;
