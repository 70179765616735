
import React, { useEffect, useState } from "react";
import sanityClient from "../client.js";
import Visalogo from "../assets/visalogo.png";
import Mastercardlogo from "../assets/mastercardlogo.png";
import {Helmet} from "react-helmet";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Header from './Header';
import Footer from './Footer';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import StarIcon from '@mui/icons-material/Star';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

export default function Creditcard() {

    const [creditData, setCreditData] = useState([]);
    const [heroData, setHeroData] = useState();

    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "discounts"] | order(order asc) {
                    name,
                    text,
                    cuponcode,
                    buttonlink,
                    boost,
                    buttontext,
                    image{
                        asset->{
                            url,
                        }
                    }
            }`
            )
            .then((data) => setCreditData(data))
            .catch(console.error);
    }, []);
    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "althero"][1]{
                    heading,
                    ingress,
                    list,
                    buttontext,
                    buttonlink,
                    image{
                    asset->{
                        url,
                        }
                    }
            }`
            )
            .then((data) => setHeroData(data))
            .catch(console.error);
    }, []);

    return (
        <>
        <Helmet>
            <title>Rabattkoder | Pengelegen</title>
            <meta name="desciption" content="Her kan du se rabattkoder" />
            <link rel="canonical" href={window.location.href} />
        </Helmet>
        <Header />
        <div className="hero-section alt">
                <div className="container">
                    <div className="inner-hero">
                        <div className="left">
                            <h1>{heroData?.heading}</h1>
                            <p className="lead">{heroData?.ingress}</p>
                        </div>
                        <div className="right">
                            <img src={heroData?.image.asset.url} alt="right-icon" />
                        </div>
                    </div>
                </div>
            </div>
        <div className="container">
                <div className="discounts">
                    {creditData &&
                    creditData.map((detail, index) => (
                        <>
                            <div className={detail?.boost ? 'single-discount boost' : 'single-discount'}>

                                {detail.boost && (
                                    <>
                                        <div className="chip-holder">
                                            <Chip icon={<StarIcon sx={{fill: '#FFEA79'}} />} label="Eksklusiv!" color="success" />
                                        </div>
                                    </>
                                )}

                                <div className="main-info">
                                    <img src={detail?.image.asset.url} />
                                </div>
           
                                <div className="main-info-text">
                                    <h3>{detail?.name}</h3>
                                    <p>
                                        <small>
                                            {detail?.text}
                                        </small>
                                    </p>
                                </div>

                                <div className="buttons">
                                    <a href={detail?.buttonlink} target="_blank">
                                        <small>Annonselenke</small>
                                        <Button variant="outlined">
                                            <LocalOfferIcon sx={{ mr: 1 }} />
                                            {detail?.buttontext}
                                        </Button>
                                    </a>
                                </div>

                            </div>
                        </>
                    ))}
                </div>
            </div>
            <Footer />
        </>
    );
}