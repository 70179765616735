import React from "react";
import { useState, useRef, useEffect } from "react";
import { useAuth } from "oidc-react";
import { useDispatch } from "react-redux";
import {
  authUpdateSessionActionCreator,
} from "../store/actions/auth";
import { useSelector } from "react-redux";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useForm } from "react-hook-form";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

function Login(props) {

  const signicat = useAuth(); //Data from BankID
  const dispatch = useDispatch();
  const storeAuth = useSelector((state) => state.auth); //Authentication Data from Redux's Store (User)
  const [consent, setConsent] = useState(false);
  const [user, setUser] = useState({
    email: "",
    phone: "",
  });
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
  });

  const signInUser = () => {
    if (user?.email && user?.phone) {
      dispatch(
        authUpdateSessionActionCreator({
          user,
          hasUserBeenSet: true,
          loanListRedirectExpected: true,
          isLoginFormOpened: false
        })
      );
      signicat.signIn();
    }
  };

  const onSubmit = () => {
    signInUser();
  };

  const handleNext = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <>
      <Button variant="outlined" onClick={props.handleClickOpen}>
        {props.buttonText}
        <ArrowForwardIcon sx={{ ml: 1 }} />
      </Button>
      <Dialog open={props.open} onClose={props.handleClose}>
        <form novalidate="novalidate" onSubmit={handleSubmit()}>
   
          <DialogContent>

          <TextField
                autoFocus
                margin="dense"
                id="phone"
                label="Telefonnummer"
                type="tel"
                required
                fullWidth
                variant="standard"
                error={errors.mobilephone_login}
                {...register("mobilephone_login", {
                    required: true,
                    minLength: 8,
                    maxLength: 8,
                    validate: (value) => value > 0,
                })}
                onBlur={($event) => {
                    setUser({ ...user, phone: $event.currentTarget.value });
                }}
            />

            {errors.mobilephone_login &&
              errors.mobilephone_login.type === "validate" && (
                <span className="error-span">
                  * Et telefonnummer har ikke bokstaver 🙂
                </span>
              )}
            {errors.mobilephone_login &&
              errors.mobilephone_login.type === "required" && (
                <span className="error-span">* Dette feltet er påkrevd</span>
              )}
            {errors.mobilephone_login &&
              errors.mobilephone_login.type === "minLength" && (
                <span className="error-span">
                  * Nummeret må være minst 8 siffer
                </span>
              )}
            {errors.mobilephone_login &&
              errors.mobilephone_login.type === "maxLength" && (
                <span className="error-span">
                  * Nummeret kan ikke være mer enn 8 siffer
                </span>
              )}

            <TextField
              margin="dense"
              id="email"
              label="E-post"
              type="email"
              fullWidth
              variant="standard"
              required
              {...register("login_email", {
                required: true,
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Please enter a valid email",
                },
              })}
              error={errors.login_email}
              onBlur={($event) => {
                setUser({ ...user, email: $event.currentTarget.value });
              }}
            />
            {errors.login_email && errors.login_email.type === "required" && (
              <span className="error-span">* Dette feltet er påkrevd</span>
            )}
            {errors.login_email?.message && (
              <span className="error-span">
                * Skriv inn en gyldig epost adresse
              </span>
            )}

            <div style={{paddingTop: "30px" }}>
              <FormGroup sx={{display: 'flex'}}>
                <FormControlLabel sx={{}} control={<Checkbox
                  onClick={() => setConsent(!consent)}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 }, ml: 1, mr: 1 }}
                />} label={
                <Typography>
                    Jeg aksepterer Mileni sine 
                    <a href="/personvern" style={{color: '#65bf7f'}}> vilkår </a>, 
                    samt at du lar oss gi deg informasjon om våre tjenester via epost og telefon.
                </Typography>
                } />
                
              </FormGroup>
            </div>
          </DialogContent>
          <DialogActions sx={{ mb: 3, mt: 3, justifyContent: "center" }}>

            <Button disabled={!user?.email || user?.email == "" || !user?.phone || user?.phone == "" || !consent} variant="contained" onClick={handleNext}>
              FORTSETT
              <ArrowForwardIcon sx={{ ml: 1 }} />
            </Button>
          </DialogActions>
        </form>
      </Dialog>

    </>  
  );
}
export default Login;
   