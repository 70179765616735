/**
 * @name: Signicat-Sign-Out-Assistance
 * @description: Fallback function that removes every OIDC keys stored in window.localStorage in case of default sign out failure of Signicat Bank ID.
 */
const signicatSignOutAssistance = () => {
  const storage = window.localStorage;
  const storageKeys = Object.keys(storage);
  const searchResults__storageKeys = storageKeys.filter((item) => {
    if (`${item}`.includes("oidc")) return true;
    return false;
  });
  for (let item of searchResults__storageKeys) {
    window.localStorage.removeItem(item);
  }
  const session = window.sessionStorage;
  const sessionKeys = Object.keys(session);
  const searchResults__sessionKeys = sessionKeys.filter((item) => {
    if (`${item}`.includes("oidc")) return true;
    return false;
  });
  for (let item of searchResults__sessionKeys) {
    window.sessionStorage.removeItem(item);
  }
};
/**
 * @name: Signicat-Session-Lookup
 * @description: Fallback function that fetches an OIDC Key from Session Storage in case the userData comes empty in Signicat
 * */
const signicatSessionLookup = () => {
  const session = window.sessionStorage;
  const sessionKeys = Object.keys(session);
  const searchResults__sessionKeys = sessionKeys.filter((item) => {
    if (`${item}`.includes("oidc")) return true;
    return false;
  });
  return (
    JSON.parse(
      window.sessionStorage.getItem(`${searchResults__sessionKeys[0]}`)
    ) || JSON.stringify({})
  );
};
/**
 * @name: Signicat-Session-User-Verification
 * @description: Checks if there is a user session active in SessionStorage
 * */
const signicatSessionLoginVerfication = () => {
  const session = window.sessionStorage;
  const sessionKeys = Object.keys(session);
  const searchResults__sessionKeys = sessionKeys.filter((item) => {
    if (`${item}`.includes("oidc")) return true;
    return false;
  });
  const user =
    JSON.parse(
      window.sessionStorage.getItem(`${searchResults__sessionKeys[0]}`)
    ) || {};
  if (user?.id_token) {
    return true;
  } else {
    return false;
  }
};
export {
  signicatSignOutAssistance,
  signicatSessionLookup,
  signicatSessionLoginVerfication,
};
