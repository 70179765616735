import React, { useEffect, useState } from "react";
import sanityClient from "../client.js";
import BlockContent from "@sanity/block-content-to-react";

export default function Refinance() {
    const [affiliates, setAffiliates] = useState();

  useEffect(() => {
    sanityClient
    .fetch(
        `*[_type == "affiliates"]{
        title,
        name,
        effektivrente,
        lanesumopptil,
        utbetalingstid,
        renteeksempel,
        buttonlink,
        detailsinfo,
        manedssum,
        slug,
        image{
        asset->{
          _id,
          url
        }
      }
    }`
      )
      .then((data) => setAffiliates(data))
      .catch(console.error);
      console.log(affiliates)
  }, []);
 
  return (
    <>
        {affiliates &&
        affiliates.map((data) => (
            <>
            <section className="affiliate">
                    <div className="affiliate-holder">
                            <div className="single-affiliate fade-me"> 
                                <div className="image-wrapper">
                                    <img src={data.image.asset.url} />
                                </div>
                                <div className="info">
                                    <div>
                                        <p><span className="font-bold">{data.effektivrente}</span></p>
                                        <p>Effektiv rente</p>
                                    </div>
                                    <div>
                                        <p><span className="font-bold">{data.lanesumopptil} kr</span></p>
                                        <p>Lånesum opptil</p>
                                    </div>
                                    <div>
                                        <p><span className="font-bold">{data.utbetalingstid}</span></p>
                                        <p>Utbetalingstid</p> 
                                    </div>
                                    <div>
                                        <p><span className="font-bold">{data.manedssum}</span></p>
                                        <p>Månedssum</p>
                                    </div>
                                    <div className="eff-rent">
                                        <p>{data.renteeksempel}</p>
                                    </div>
                                </div>
                                <div className="misc">
                                    <div className="detail-button font-bold">
                                        Detaljer
                                        <span className="icon-fi-rr-angle-small-down"></span>
                                    </div>
                                    <a target="_blank" href={data.buttonlink} className="affiliate-button font-bold">
                                        Søk her
                                        <span className="icon-fi-rr-arrow-right"></span>
                                        <p className="ad font-light">Annonse</p>
                                    </a>
                                </div>
                                <div className="details">
                                    <BlockContent blocks={data.detailsinfo} />qweqwe
                                </div>
                            </div>
                </div> 
            </section>
                
            </>
        ))}
    </>
  );
}